* {
  box-sizing: border-box;
}

img,
svg {
  vertical-align: middle;
}
*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
*::-webkit-scrollbar-track:horizontal {
  background: white;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
*::-webkit-scrollbar-track:vertical {
  background: white;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: inset 0 0 100px #999999;
  border: 2px solid transparent;
  border-radius: 32px;
}

// Basic font-size for REM units
html {
  font-size: 16px;
}

/* Keep the font-family list the same as "fontFamilyArray" constant */
body {
  margin: 0;
  font-family: 'Noto Sans', 'Noto Serif', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC',
    'Noto Sans TC', 'Noto Naskh Arabic', 'Noto Sans Hebrew', 'Noto Sans Devanagari',
    'Noto Sans Bengali', 'Noto Sans Tamil', 'Noto Sans Thai', 'Noto Sans Armenian',
    'Noto Sans Georgian', 'Noto Sans Ethiopic', 'Noto Sans Sinhala', 'Noto Emoji',
    'Noto Color Emoji', sans-serif;
}
